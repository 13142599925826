.countdown {
    position: absolute;
    right: 10px;
    top: 10px;
    opacity: .5;
}

.warningText {
    color: #04c8c8;
    opacity: .8;
    font-size: 12px;
    line-height: 14px;
    margin-inline: min(4rem, 5vw);
    margin: 10px 5px;
}

.qrContainer {
    border: 1px solid #04c8c87f;
    border-radius: 5px;
    overflow: hidden;
}

.qrContainer img {
    width: clamp(180px, 1rem + 20vw, 20rem);
    height: clamp(180px, 1rem + 20vw, 20rem);
}

.buttonContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}