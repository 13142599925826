//
// Aside extended
//

// General mode(all devices)
.aside {
	display: flex;
	flex-direction: column;
	width: get($aside-config, width, desktop);
	
	// Root links
	.menu {
		align-items: stretch;
		justify-content: center;

		> .menu-item {
			> .menu-link {
				padding: 0;

				.menu-icon {
					margin: 0;
					flex-shrink: 0;
					width: 45px;
					height: 45px;
					@include border-radius($border-radius);

					i,
					.svg-icon {
						color: $white !important;
					}
				}
									
				&:hover {
					.menu-icon {
						background-color: rgba($white, 0.1);

						i,
						.svg-icon {
							color: $white !important;
						}
					}
				}

				&.active {
					.menu-icon {
						background-color: $white;

						i,
						.svg-icon {
							color: $primary !important;
						}
					}
				}
			}

			&.here > .menu-link,
			&.show > .menu-link {
				.menu-icon {
					background-color: rgba($white, 0.3);

					i,
					.svg-icon {
						color: $white !important;
					}
				}
			}
		}	
	}

	// Custom button
	.btn-custom {
		@include button-custom-variant(
			$color: $white, 
			$icon-color: $white, 
			$border-color: null, 
			$bg-color: null, 
			$color-active: $white, 
			$icon-color-active: $white, 
			$border-color-active: null, 
			$bg-color-active: rgba($white, 0.1)
		);
	}
}

// Desktop mode
@include media-breakpoint-up(lg) {
	.aside {
		width: get($aside-config, width, desktop);

		// Fixed aside mode
		.aside-fixed & {
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			z-index: get($aside-config, z-index);
		}

		// Static aside mode
		.aside-static & {
			position: relative;
			z-index: 1;
		}
	}
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
	.aside {
		display: none;
		width: get($aside-config, width, tablet-and-mobile);
	}
}
